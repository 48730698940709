import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Divider, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Toast, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/della.mp3'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';


let cmain = '#045A6E'
let mainfont = '#045A6E'
let black = 'rgb(38,38,38)'
export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()
    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      hadir: true,
      err: [],
      submitted: '',
      sesi: 1,
      showgift:false,
      show:false,
      show1:false
        }
  }
  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
    var countDownDate = new Date("03/13/2021").getTime();
    // Update the count down every 1 second
    var x = setInterval(() => {
      // Get today's date and time
      var now = new Date().getTime();
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })
    }, 1000);
  }

  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/mp3';
    snd.play();
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('top');
      elmnt.scrollIntoView();
    }, 1000)
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}${this.state.hadir?" sesi:"+this.state.sesi:''}", jumlahhadir: "", kepada: "della-zulfan", pesan:"${this.pesan.current.value}",alamat: ""`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
              
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }


  }
  render() {
    let { hadir, days, hours, minutes, seconds, hide, submitted, err, sesi ,
    showgift,show,show1} = this.state
    let slide = [
      "	https://i.ibb.co/WWvq4bv/1.jpg	",
    "	https://i.ibb.co/sFjpDmt/2.jpg	",
    "	https://i.ibb.co/SXNNdny/3.jpg	",
    "	https://i.ibb.co/Mp8YnhW/4.jpg	",
    "	https://i.ibb.co/GP9tXXg/5.jpg	",
      "	https://i.ibb.co/vztdRZX/6.jpg	",
    "	https://i.ibb.co/gF3v9rb/7.jpg	",
    "	https://i.ibb.co/DfQr6nj/8.jpg	", 
         
    ]
    let slider = []
    slide.map(v => {
      slider.push(gambar(v, 95,'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
    })
    let query = this.useQuery().get('name');
    query = query ? cap(query) : ''

    return (
      <>
        <Helm
          title='Undanganku - Della & Zulfan'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
          img={slide[0]}
          url='https://undanganku.me/della-zulfan'
        />

        <div id='gold5' style={{
          backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
          backgroundSize: 'cover', backgroundPosition: 'center'
        }}>
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw',
            backgroundColor: 'transparent'
          }}>
            <Container fluid id='g3-header' className='relative' style={{
              backgroundImage: `url('https://i.ibb.co/MkKz2qX/Modal.jpg')`
            }}>
              <Item>
                <Col xs={12} md={4} className='m-2 m-md-0 '>
                  <img className='img-fluid w-100 p-2'
                    src={gambar('https://i.ibb.co/z7vdRvW/Logo.png')} data-aos="fade-left" />
                </Col>
              </Item>
              <Item>
                {
                  <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                    Kepada Yth :<br /> Bapak/Ibu/Saudara/i</h2>
                }</Item>
              <Row className='justify-content-center'>
                <div onClick={() => { this.play() }}
                  
                  className={`col-md-4 button roboto-slab text-center btn ${hide ? 'show' : 'hide'}`}
                  style={{ marginTop: 0 ,color:'white'}}>
                  Open Invitation
                  </div>
              </Row>
            </Container>

            <div className={hide ? 'd-none' : 'd-block'}>
              <div id="top" style={{ backgroundColor: 'transparent' }}>
                <Container className="dinny px-3 pt-5 ">
                  <Item>
                    <p className="fs24 text-center cblack">
                        <span className="fs16">
                            And one of His signs is that He created for you spouses from
                             among yourselves so that you may find comfort in them. And 
                             He has placed between you compassion and mercy. Surely in 
                             this are signs for people who reflect. (Ar-Rum :21)
                        </span>
                    </p>
                  </Item>
                  <Item>
                      <Col xs={6} sm={2}>
                        <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                            <p className='text-center p-2 px-4 ' style={{color:mainfont}}>
                            The Intimate Wedding of Della & Zulfan
                              </p>
                          </Item>
                </Container>
                <Container id='sectiongold55' className="py-5 dinny" >

                  <Item>
                    <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Della
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/84XvwZF/Individual-1.png', 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", 
                          color: mainfont }}>
                          Della Dwi Heriyani
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                            <b>The Daughter of: </b><br />
                            Alm.Bpk. Gatot Suhari<br />
                        &<br />
                        Ibu Sri Mulyani 
                      </p>
                        </Item>
                        <Item>
                          <Col xs={4} sm={2}
                            onClick={() => { window.open('https://instagram.com/dedellariyani') }}>
                            <img src={logoig} className='img-fluid w-100 btn' />
                          </Col>
                        </Item>
                      </div>
                    </div>
                    <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Zulfan
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/5sgDW8C/Individual-2.png', 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                          Muhamad Zulfan Firdaus
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                            <b>The Son of : </b><br />
                            Bpk. H. Rukmana Hidayat
                        <br />
                        &<br />
                        Ibu Hj. Yati Suciati
                      </p>
                        </Item>
                        <Item>
                          <Col xs={4} sm={2} 
                            onClick={() => { window.open('https://instagram.com/zulfanmuhamad') }}>
                            <img src={logoig} className='img-fluid w-100 btn' />
                          </Col>
                        </Item>
                      </div>
                    </div>
                  </Item>
                </Container>
                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                  <Item>
                    <p className="fs16">
                      Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                  </Item>
                  <Item>
                    <p className="fs20">
                      <b>
                        SABTU <span className="fs36">13</span> MAR 2021
                      </b>
                    </p>
                  </Item>
                  <Item>
                    <p className="fs20 col-sm-4" style={{ color: mainfont }}>
                      <b>Akad Nikah </b><br />
                      <span className="cblack fs16">
                      08.00 WIB - Selesai
                        <br />
                      </span>
                    </p>
                    <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                    </div>
                    <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{color:'#045A6E' }}>
                      <b>Resepsi</b><br />
                      <span className="cblack fs16">
                      Sesi 1: 10.00 s/d 11.30 WIB 
                      </span> <br />
                      <span className="cblack fs16">
                      Sesi 2: 11.30 s/d 13.00 WIB
                      </span>
                    </p>
                    <p className="w-100 fs20 text-center">
                        <b>
                        Rumah Makan Ponyo Cinunuk
                        </b><br/>
                        Jalan Raya Tagog Cinunuk No.186, Cinunuk, Cileunyi, Bandung, Jawa Barat 40624
                    </p>
                  </Item>
                  
                  <Item>
                    <Col xs={10} sm={3}
                      style={{
                        border: `2px solid ${cmain}`,
                        borderRadius: '10px'
                      }}
                      onClick={() => {
                        window.open("https://www.google.com/maps/dir//Rumah+Makan+Ponyo,+Jl.+Raya+Tagog+Cinunuk+No.186,+Cibiru+Wetan,+Cileunyi,+Kota+Bandung,+Jawa+Barat+40623/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x2e68c3394c41ded3:0x7075858f2c9c3d1d?sa=X&ved=2ahUKEwjNj9Wz0pvtAhXR63MBHVKFAsAQ48ADMAB6BAgLEBw")
                      }}
                      className="p-2 mx-sm-2 btn">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                          <b>Get Direction</b>
                        </p>
                      </Item>
                    </Col>
                    <Col 
                      onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=NmsxMzc3bHY4bzdrYWNrZnNsbjBwZzNybjUgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                      xs={10} sm={3}
                      style={{
                        border: `2px solid ${cmain}`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                          <b>Add to Calendar</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>
                <Container id='sectiongold57'>
                  <div className='py-3'>

                    <Item>
                      <div data-aos="fade-left" data-aos-duration="1000"
                        className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                        <Item>
                          <div className='item'>
                            <Item>
                              <div>
                                {days}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Days
                                </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {hours}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Hours
                      </span>
                            </Item>
                          </div>
                          <div className='dot'>:</div>
                          <div className='item'>
                            <Item>
                              <div >
                                {minutes}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Mins
                      </span>
                            </Item>
                          </div>
                          <div className='dot' >:</div>
                          <div className='item'>
                            <Item>
                              <div>
                                {seconds}
                              </div>
                            </Item>
                            <Item>
                              <span>
                                Secs
                      </span>
                            </Item>
                          </div>
                        </Item>

                      </div>
                    </Item>
                  </div></Container>
                <Container className="text-center dinny">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: mainfont
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className="cblack text-center w-100 fs16">
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Gunakan Masker
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              jaga Jarak
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={salaman} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                            Cukup salam namaste tanpa bersentuhan
                      </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container>


                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                  <Slider slide={slider} />
                </Container>
                <Container id='sectiongold56'>
                  <div className='pt-3'>
                    
                    <div data-aos={`fade-right`} data-aos-duration="2000">
                      <Item>
                        <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                          <Item>
                            <p className='text-center p-2 px-4'>
                            Whatever our souls are made of, his and mine are the same. <br/>- Emily Brontë -
                              </p>
                          </Item>
                        </div>

                      </Item>
                    </div>
                  </div>
                </Container>

                <Container id='sectiongold58' >

                  <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                    <Item>
                      <Col xs={4} lg={2}>
                        <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                      </Col>
                    </Item>
                    <Item>
                      <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                        <Item>
                          <h1 style={{
                            fontFamily: '"Marck Script", cursive',
                            color: mainfont
                          }}>
                            Send Your Wishes
                    </h1>
                        </Item>
                        <Item>
                          <form className="col-12 w-100">
                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                            <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1">Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"

                                    >Tidak Hadir</label>
                                  </div>
                                 
                                
                                {!hadir ? false : (
                                    <>  <Alert variant='dark col-12 mr-4 '>
                                      <p className="f-14">
                                      Silahkan Pilih Jadwal Kedatangan
                                      </p>
                                      <div onClick={() => {
                                        this.setState({ sesi: 1})
                                      }
                                      }
                                      className="pl-5">
                                        <input type="radio" id="featured-3" name="sesi" checked={sesi==1 ? true : false} />
                                        <label for="featured-3">
                                        
                                        Sesi 1: 10.00 s/d 11.30 WIB </label>
                                      </div>
                                      <div onClick={() => {
                                        this.setState({ sesi: 2 })
                                      }
                                      } className="pl-5">
                                        <input type="radio" id="featured-4" name="sesi" checked={sesi==2 ? true : false} />
                                        <label for="featured-4"

                                        > Sesi 2: 11.30 s/d 13.00 WIB</label>
                                      </div>
                                      <p className="f-14">
                                      Tamu undangan diharapkan hadir sesuai dengan sesi yang telah ditentukan

                                      </p>
                                    </Alert>
                                      
                                       
                                                       
                                    </>

                                  )}
                                  </div>
                              </div>
                            </Item>
                            

                            <Item>
                              <Col xs={12} className=''>
                                {
                                  submitted == true ? (
                                    <Alert variant='success' style={{fontSize:'16px'}}>
                                      Pesan anda sudah disampaikan
                                    </Alert>) : (submitted === false ? (
                                      <Alert variant='danger' style={{fontSize:'16px'}}>
                                        {
                                          err.map(val => {
                                            return (
                                              <li>{val}</li>
                                            )
                                          })
                                        }

                                      </Alert>
                                    ) : false)
                                }

                              </Col>
                            </Item>
                            <Item>
                              <div className='col-6 button rounded btn'
                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color:'white'}} > Kirim </div>
                            </Item>
                          </form>
                        </Item>
                      </div>
                    </Item>
                  </div>
                </Container>
                <Container className="py-3" fluid >
                  <Item>
                  <Col 
                      onClick={() => this.setState({showgift:!showgift})}
                      xs={10} md={4}
                      style={{
                        border: `2px solid #86835dff`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-md-2 mt-3 mt-md-0">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                          style={{ width: "10%", height: '10%' }} />
                        <p className="mb-0 my-auto ml-3" style={{ color: '#86835dff' }}>
                          <b>Send Gift (Klik Disini)</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                  </Container>
                  <Container 
                  className={`text-center mt-3 aulia-wedding py-3 px-3 ${!showgift?'trsnone':'trs'}`} >
                  <Item>
                    <Col className="py-3 px-md-5 mx-2" style={{ backgroundColor:cmain, borderRadius: '10px',color:'#86835dff' }}>
                      <h1 style={{color:'white'}}>
                        Wedding Gift
                      </h1>
                          <Item>
                            <Col xs={8} md={4}>
                              <img src='https://i.ibb.co/0y5mGMv/linkaja.jpg' className='p-2 w-100 img-fluid'/>
                            </Col>
                          </Item>
                          <p className="text-center w-100" style={{color:'white'}}>
                            Link Aja
                          </p>
                          <Item>
                            <Col xs={8} md={4}>
                              <img src='https://i.ibb.co/YRxWfn4/dana.png' className='p-2 w-100 img-fluid'/>
                            </Col>
                          </Item>
                          <p className="text-center w-100" style={{color:'white'}}>
                            Dana
                          </p>
                          <Item>
                            <Col xs={8} md={4}>
                              <img src='https://i.ibb.co/5FW90Nh/ovo.png' className='p-2 w-100 img-fluid'/>
                            </Col>
                          </Item>
                          <p className="text-center w-100" style={{color:'white'}}>
                            OVO
                          </p>
                        <Item>
                          
                          
                          
                          <Toast onClose={() =>this.setState({show1:false})} show={this.state.show1} delay={3000} autohide >
                            <Toast.Body>Copied!</Toast.Body>
                          </Toast>
                          <CopyToClipboard text="081214129545">
                          <Col xs={8} className='btn button mb-5 mt-1'
                          
                          onClick={(e)=>{
                            this.setState({show1:true})
                          }}>
                            081214129545
                            
                            <p className="w-100 text-center mb-0" style={{fontSize:'14px',color:'#86835dff'}}>
                            (Click to Copy)
                            </p>
                          </Col>
                          </CopyToClipboard>
                          {/* <p className="text-center w-100">
                            Alamat Pengiriman Kado 
                          </p>
                          <p className="text-center w-100 px-3">
                            <b>
                            Jl. Lorem Ipsum
                            </b>
                          </p> */}
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>
                  </Item>
                </Container>                
                  
                <Container className='text-center mb-5' id='sectiongold59'>
                  <Item>
                    <h1 data-aos="zoom-in" data-aos-duration="1000" style={{color:mainfont}}>
                      Possible Wedding
              </h1>
                  </Item>
                  <Item>
                    <h2 data-aos="zoom-in" data-aos-duration="1000" style={{color:mainfont}}>
                      Digital Invitation
              </h2>
                  </Item>
                  <Item>
                    <div className='col-3 col-lg-1' onClick={()=>{window.open('https://instagram.com/possiblewedding')}} >
                      <img src={logoig} className='img-fluid w-100'
                      />
                    </div>
                  </Item>

                </Container>
              </div>
            </div>
          </div>
        </div>

      </>


    )
  }
}

